@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=M+PLUS+Rounded+1c&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=M+PLUS+Rounded+1c&display=swap');

:root {
  --background-color: #f6f6f6; /* Default light theme background */
  --text-color: #000; /* Default light theme text color */
  --highlight-color: #007AFF; /* Default light theme highlight color */

}

.header-background, .title-text {
  transition: background 0.1s ease;
}

.footer-icons-right-corner, .footer {
  transition: color 0.1s ease, background-color 0.3s ease;
}

/* Dark theme variables */
.dark-theme {
  --background-color: #141414;
  --text-color: #fff;
  --highlight-color: rgb(88, 86, 214); 
}



html {
  scroll-behavior: smooth;
  background-color: var(--background-color); /* Use variable for background color */
  color: var(--text-color); /* Use variable for text color */

}

.header-background{
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 50%, var(--background-color) 100%);
  backdrop-filter: blur(10px);
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 50%);

}

.App {
  text-align: center;
}

.App-hero {
  height: 80vmin;
  pointer-events: none;
  transition: filter 0.1s ease;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-logo-appstore{
  height: 10vmin;
  cursor:pointer;
}

.light {
  background-color: #f6f6f6;
  color: black;
}

.dark {
  background-color: #141414;
  color: white;
}

.highlight-dark {
  color: var(--highlight-color);
}
.highlight-light {
  color: var(--highlight-color);
}

/* .blur {
  filter: blur(25px);
  opacity: 0.5;
} */

.footer-icons-right-corner {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 0;
  right: 0; /* Position to the right */
  margin: 0;
  padding-right: 20px;
  padding-bottom: 20px;
}

p{
  font-family: "Inter", sans-serif;
}

h1{
  font-family: "Concert One", sans-serif;
  font-size: 2rem;
}

.App-header {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  font-family: "Concert One", sans-serif;
  font-weight: 700;
  font-size: 6rem;
}

.title-text{
  transform: translateY(-100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, var(--background-color) 85%);
  backdrop-filter: blur(10px);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 25%);
}
.highlight {
  color: rgb(0, 122, 255); /* Change this to your desired color */
}
.description-text{
  transform: translateY(-100px);
  font-size: 1.5rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  width: 50%;
  margin: 0 auto;
  display: flex;
    flex-direction: column;
    align-items: center;
}

b{
  font-family: "Concert One", sans-serif;
  font-weight: 400;

}

body{
  margin: 0;
  width: 100%;
  margin: 0 auto;
  font-size: 1.5rem;

}
.privacy-policy{
  width: 55%;
  margin: 0 auto;
}
.title{
  font-size: 6rem;
  line-height: 10rem;
 
}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 1.5rem;
}
button{
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.button-img {
  transition: filter 0.3s ease, transform 0.3s ease, opacity 0.3s ease;
  transform-origin: center;

}

.button-img.blur {
  filter: blur(1px);
  transform: scale(0.8);
  opacity: 0.5;
}

h1[type='banner']{
  color: black;
  font-size: 25px;
}
.monthly {
  background: orange; 
  padding: 0px 20px 0px 10px; 
  border-radius: 30px; 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  margin-bottom: 25px;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2), 0px 5px 20px 0px rgba(255,149,0,0.2);
  height: 45px;
  border: 2px solid #ffd599;
  width: fit-content;
  margin: auto;
  margin-top: 50px;
  cursor: pointer;
}
a{
  text-decoration: none;
  color: black;
}

@media (max-width: 768px) {
  .monthly {
    padding: 0px 15px 0px 5px; 
    margin-bottom: 25px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2), 0px 5px 20px 0px rgba(255,149,0,0.2);
    height: 30px;
    margin-top: 50px;
  }

  h1[type='banner']{
    color: black;
    font-size: 15px;
  }
  body{
    font-size: 12pt;
    
  }
  .privacy-policy{
    width: 90%;
  }
   .App-header {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: rgb(0, 0, 0);
    font-family: "Concert One", sans-serif;
    font-weight: 700;
    font-size: 15pt;
  } 
 
  .App-hero {
    height: 100vmin;
    pointer-events: none;
    /* background: rgb(145, 35, 35); */
  }
  .title{
    font-size: 25pt;
    line-height: 8rem;
   
  }
  .description-text{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
  }
  .footer{
    font-size: 1rem;
    
  }
}